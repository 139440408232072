<script setup lang="ts">
import * as Sentry from '@sentry/vue'

import {Autoplay} from 'swiper/modules';

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

const props = defineProps({
  endpoint: {
    type: String,
    required: true
  },
});

const breakPoints = {
  '600': {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  '875': {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  '1200': {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  '1400': {
    slidesPerView: 5,
    spaceBetween: 20,
  },
  '1700': {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  '1900': {
    slidesPerView: 6.8,
    spaceBetween: 30,
  },
}

const {data, pending, error, refresh} = await useFetch(useRuntimeConfig().public.baseURL + props.endpoint)
let products = []
if (!error.value) {
  products = data.value.data
}
if (error.value) {
  Sentry.captureMessage(error.value.message)
}
const modules = [Autoplay]
</script>

<template>
  <div>
    <div class="flex justify-between">
      <slot name="title">
        <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Products</h2>
      </slot>
      <slot name="button">
        <NuxtLink href="/products" class="btn btn-primary">Browse all products</NuxtLink>
      </slot>
    </div>

    <swiper
        class="mt-4"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :breakpoints="breakPoints"
        :autoplay="{ delay: 2000 }"
        :scrollbar="{ draggable: true }"
    >
      <swiper-slide v-for="product in products" :key="product.id" class="h-auto">
        <product-card :product="product"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>

</style>